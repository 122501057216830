$font-family: 'Roboto', sans-serif;
$font-family-name: 'Vollkorn', serif;

$red: #f03c2d;
$light: #a0b9d2;
$dark: #222c35;
$link-color: $red;
$link-border: 2px;
$link-border-color: $red;
$color-background: $dark;
$color-dark: $light;
$color-light: $red;
$color-titles: #293340;
$color-titles-text: $light;
$color-complementary: $red;

@import 'normalize';

body {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #a0b9d2;
  background: #222c35; }

p, li {
  margin-bottom: 1em;
  line-height: 1.5em;
  
}

.post .post-content > p:first-child:first-letter {
    color: #f03c2d;
    float: left;
    font-family: Vollkorn;
    font-size: 4em;
    line-height: 1;
    padding-top: 4px;
    padding-right: 8px;
}

a {
  text-decoration: none;
  color: $link-color;
  
  &:hover
  {
	  border-bottom: $link-border $link-border-color solid;
  }
  
  &.link-plain:hover
  {
      border-bottom: inherit;
  }  
}

strong {
    font-weight: bold;
}
 
h2 {
	font-weight: inherit;
}

ol, ul {
	list-style-position: outside;
    padding-left: 1em;
}

ul {
    list-style: square;
}


.main-container {
    width: 700px;
	margin: 0 auto;

}

img {
    max-width: 100%;
}

.header {

    text-align: left;
    margin: 200px auto 150px auto;
	
	p {
		margin-bottom: 1em;
	}

    &__name {
        font-size: 32px;
        margin-bottom: 10px;
        color: $color-dark;
		font-family: $font-family-name;
		letter-spacing: 0.3px;
		width: 65%;
    }
	&__nickname {
		font-size: 50%;
		position: relative;
		bottom: -0.25em;
	}
    &__hi {
        text-transform: uppercase;
		letter-spacing: 4px;
    }
	
	&--reduced {
		margin: 30px auto 75px auto;
		border-bottom: 2px solid;
		padding-bottom: 1em;
	}
	
	&--reduced &__name {
		position: relative;
	}
	
	&__exitButton {
		position: absolute;
		left: -2em;
		
		a {
			font-size: 150%;
			line-height: 85%;
		}
	}
	
}

.horizontal-list {
	
	&__element {
		$separationBetweenElements: 10px;
		display: inline;
		color: $color-dark;
		margin-right: $separationBetweenElements;

		&:before {
			content: ' • ';
			margin-right: $separationBetweenElements;
		}
		&:first-child:before {
			content: '';
		}

		&-title {
			color: $color-complementary;
			font-weight: bold;
		}
	}
}


.section {
		
	&__header {
		margin: 1em 0;
		font-size: 110%;
		margin: 1.36em auto;
		text-transform: uppercase;
	}
	
	&__title {
		background: #293340;
		box-sizing: border-box;
		padding: 8px 10px;
		position: relative;
		left: -10px;
	}
	
}

.zen-list {
	
	margin-top: 0;
	margin-bottom: 0;
	
	&__element {
		margin-bottom: 1.5em;
	}
}

.post {
	
	&__header {
		margin-bottom: 2em;
	}
	
	&__title {
		font-size: 1.6em;
		margin-bottom: 0.5em;
	}
	&__meta {
		font-size: 90%;
	}
}

.footer {
	margin: 150px 0 75px 0;
	
	&__image {
		display: block;
		margin: 0 auto;
	}
}

.archive-post
{
	margin: 0;
	
    & > p {
		line-height: 1.5;
	    margin-top: 2em;
		margin-bottom: 0;
	}
	
	& > &__excerpt {
		font-size: 90%;
		line-height: 1.67;
		margin-top: 0.56em; // 1.67em;
	}

}

.label404 {
    text-align: center;
    font-size: 5em;
    font-family: Vollkorn;
}